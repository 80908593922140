<style>
.c-table-header {
  border-radius: 5px;
  border: 2px solid #7f8387;
  padding: 1rem;
}
.c-table-title {
  position: absolute;
  margin-top: -27px;
  margin-left: 1rem;
  z-index: 1;
  cursor: pointer;
}
.c-table-exfilters {
  transition: all 1s;
}
.c-table-spacer {
  margin-top: .5rem;
}
.c-table-st-clear {
  cursor: pointer;
}
.c-table-progress-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-left: -1rem;
  margin-right: auto;
  margin-top: 3rem;
}
.c-table-progress-overlay.active {
  display: block;
}
@media (max-width: 1024px) {
  .c-table-sm-spacer {
    margin-top: .5rem;
  }
  .c-sm-spacer {
    margin-top: 1rem;
  }
}
</style>
<script>
import TimeAgo from 'vue2-timeago';
import VueSlideBar from "vue-slide-bar";
import { HalfCircleSpinner } from 'epic-spinners';

import {get_acsrf_token, startsWith} from "@/methods";

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import CopyableInput from "@/components/CopyableInput";
import {mapGetters} from "vuex";
import {BucketType} from "@/enums";
import {FormWizard, TabContent} from "vue-form-wizard";

const humanizeDuration = require("humanize-duration");

export default {
  props: ['server_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableInput,
    VueSlideBar,
    HalfCircleSpinner,
    FormWizard,
    // eslint-disable-next-line vue/no-unused-components
    TabContent,
    // eslint-disable-next-line vue/no-unused-components
    TimeAgo,
  },
  validations: {
    filter: {
      term: {required, minLength: minLength(1), maxLength: maxLength(64)},
      message: {required, minLength: minLength(1), maxLength: maxLength(128)}
    },
  },
  methods: {
    async fetchData() {
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.add('active');
      let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/queryBucket`);
      url.searchParams.append('bucket_type', this.bucketType);
      url.searchParams.append('page', this.currentPage);
      url.searchParams.append('limit', this.perPage);
      url.searchParams.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
      url.searchParams.append('sort', JSON.stringify({
        key: this.sortBy,
        dir: this.sortDesc ? -1 : 1
      }));
      url.searchParams.append('query', JSON.stringify(this.query));
      let _queryInit = (new Date).getTime();
      this.items = await fetch(url, {credentials: 'include'})
          .then(res => {
            if(res.status === 429) {
              this.$toast.warning(this.$t('error.server.ratelimit.short'));
            } else if(res.status === 204) {
              this.available = false;
            } else if(res.status === 206) {
              this.available = false;
              this.banlistRequirement = true;
            }
            return res.json();
          })
          .then(data => {
            this.totalItems = parseInt(data.meta.total_items, 10);
            return data.entries;
          })
          .then(items => items)
          .catch(error => {
            this.query.delta = 0.0;
            if(this.available) this.$toast.error('Failed to contact query service');
            if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.remove('active');
          });

      this.query.delta = (new Date).getTime() - _queryInit;
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.remove('active');
    },
    currentDateTime() {
      let date = new Date();
      return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    async clearSearchTerm() {
      this.query.search_term = null;
      await this.adhocSearch();
    },
    async adhocSearch() {
      await this.fetchData();
    },
    filtersToggle() {
      this.query.full = !this.query.full;
    },
    timeTrick(seconds) {
      return new Date(Date.now() - 1000 * seconds);
    },
    async openSettings(event) {
      this.$emit('tabChangeRequest', 'DSettings');
      event.preventDefault();
    },
    getRouteForBucketType() {
      switch(this.bucketType) {
        case BucketType.WHITELIST: {
          return 'whitelist';
        }
        case BucketType.RESERVED_SLOT: {
          return 'reservedslot';
        }
        case BucketType.QUEUE_PRIORITY: {
          return 'queuepriority';
        }
        case BucketType.NAME_FILTER: {
          return 'namefilter';
        }
        case BucketType.CHAT_FILTER: {
          return 'chatfilter';
        }
      }
    },
    async deleteEntry(uuid) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          uuid: uuid
        };

        let route = this.getRouteForBucketType();
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/${route}`);
        let response = await fetch(url, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.chatfilter.remove.success.title'),
            text: this.$t('server.view.chatfilter.remove.success.text')
          });
          await this.fetchData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    },
    async createEntry() {
      this.$refs.entryWizard.hide();
      this.$emit('actionEnableFullpage', this.$t('server.view.chatfilter.add.deploy'), 'primary', true);
      this.$emit('actionToggleFullpage');
      await new Promise(resolve => setTimeout(resolve, 2000));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          term: this.filter.term,
          action: this.filter.action,
          message: this.filter.message,
          duration: this.filter.duration || 0,
          permanent: this.filter.permanent
        };

        this.filter.term = null;
        this.filter.action = null;
        this.filter.message = null;
        this.filter.duration = null;
        this.filter.permanent = true;

        let route = this.getRouteForBucketType();
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/${route}`);
        let response = await fetch(url, {
          method: 'PUT',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('server.view.chatfilter.add.success')
          });
          await this.fetchData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));
          } else if(response.status === 400) {
            let data = await response.json();
            if(data.error === 'invalid-regex') {
              this.$swal({
                icon: 'error',
                text: this.$t('server.view.chatfilter.add.invalidregex')
              });
            } else throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.$emit('actionToggleFullpage');
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        this.$v.filter.$touch();
        if(this.$v.filter.$error) reject('Error');
        if(this.filter.duration > 1051200) reject('Error');
        if(this.filter.action === null || this.filter.action === '') reject('Error');
        resolve(true);
      })
    },
    validateFormTerm() {
      return new Promise((resolve, reject) => {
        this.$v.filter.term.$touch();
        if(this.$v.filter.term.$error) reject('Error');
        resolve(true);
      })
    },
    addEntry() {
      this.$refs.entryWizard.show();
    },
    humanizeDuration(duration) {
      return humanizeDuration(duration * 1000 * 60);
    }
  },
  computed: {
    ...mapGetters([
      'getUILanguage',
      'getDTLocale'
    ]),
    positionQuery: {
      get() {
        return `${this.query.position.coordinates[0]}, ${this.query.position.coordinates[1]}`;
      },
      set(val) {
        let f = val.match('^(-?\\d+(\\.\\d+)?),\\s*(-?\\d+(\\.\\d+)?)$');
        if(f === null) return this.$refs.positionQueryInput.$el.classList.add('is-invalid');
        else this.$refs.positionQueryInput.$el.classList.remove('is-invalid');
        let x = f[1];
        let y = f[3];
        this.query.position.coordinates = [x, y];
      }
    },
    customFieldLabel() {
      return this.$t(`server.view.players.columns.custom.${this.query.customField}`);
    }
  },
  watch: {
    currentPage: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        });
      }
    },
    sortBy: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    },
    sortDesc: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    }
  },
  mounted() {
    this.fetchData().catch(error => {
      console.error(error)
    });
  },
  data() {
    return {
      ready: false,
      available: true,
      banlistRequirement: false,
      error: false,
      items: [],
      bucketType: BucketType.CHAT_FILTER,
      inProgress: false,
      filter: {
        error: false,
        term: null,
        action: null,
        message: null,
        duration: null,
        permanent: true
      },
      fields: [
        {
          key: 'created_at',
          label: this.$t('server.view.bucket.columns.created'),
          sortable: true
        },
        {
          key: 'creator.cftools_id',
          label: this.$t('server.view.bucket.columns.admin'),
          sortable: true
        },
        {
          key: 'term',
          label: this.$t('server.view.bucket.columns.term'),
          sortable: true
        },
        {
          key: 'action.name',
          label: this.$t('server.view.bucket.columns.action'),
          sortable: true
        },
        {
          key: 'actions',
          label: '',
          sortable: false
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "created_at",
      sortDesc: true,

      // Query
      queryDateStart: false,
      query: {
        delta: 0.0,
        full: false,
        search_term: null,
        date: {
          start: {
            enabled: false,
            value: this.currentDateTime()
          },
          end: {
            enabled: false,
            value: this.currentDateTime()
          }
        },
        position: {
          available: false,
          enabled: false,
          coordinates: [0.0, 0.0],
          radius: 100.0
        }
      }
    }
  }
};
</script>

<template>
  <div v-if="available">
    <div class="card" >
      <div class="card-body">
        <div class="c-table-header">
          <div class="c-table-title" v-on:click="filtersToggle()">
            <span class="badge badge-primary">
              <h6 class="mb-0 mt-0">
                {{ $t('components.table.filters.title') }}
                <small>
                  {{ $t('components.table.filters.details') }}
                </small>
              </h6>

            </span>
          </div>
          <!-- Primary search term -->
          <div class="c-table-spacer"/> <!-- Spacer -->
          <div class="row">
            <div class="col-lg col-sm-12">
              <div class="mt-lg-2">
                {{$t('components.table.filters.search_term')}}
                <span class="badge badge-primary mb-1 c-table-st-clear" v-on:click="clearSearchTerm()">
                  {{ $t('components.table.filters.clear') }}
                </span>
              </div>
              <b-form-input
                  for="text"
                  v-model="query.search_term"
                  :placeholder="$t('components.table.filters.search_term')"
                  v-on:keyup="$event.keyCode === 13 ? fetchData() : null"
              />
            </div>
          </div>
          <div class="c-table-exfilters" v-if="query.full">
            <!-- Date range -->
            <div class="c-table-spacer"/> <!-- Spacer -->
            <div class="row justify-content-center">
              <div class="col">
                <div class="row">
                  <div class="col-lg-3 col-sm-12 text-lg-center">
                    <b-form-checkbox
                        switch
                        v-model="query.date.start.enabled"
                        class="mt-lg-2"
                    >
                      {{ $t('server.view.players.created.after') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-lg col-sm-12">
                    <div class="c-table-sm-spacer"/> <!-- Spacer -->
                    <b-form-input
                        id="date-time"
                        v-model="query.date.start.value"
                        type="datetime-local"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="c-table-sm-spacer"/> <!-- Spacer -->
                <div class="row">
                  <div class="col-lg-3 col-sm-12 text-lg-center">
                    <b-form-checkbox
                      switch
                      v-model="query.date.end.enabled"
                      class="mt-lg-2"
                    >
                      {{ $t('server.view.players.created.before') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-lg col-sm-12">
                    <div class="c-table-sm-spacer"/> <!-- Spacer -->
                    <b-form-input
                      id="date-time"
                      v-model="query.date.end.value"
                      type="datetime-local"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Special features, GEO etc. -->
            <template v-if="query.position.available">
            <div class="c-table-spacer"/> <!-- Spacer -->
            <div class="row justify-content-center">
              <div class="col">
                <div class="row">
                  <div class="col-lg-3 col-sm-12 text-lg-center">
                    <b-form-checkbox
                        switch
                        v-model="query.position.enabled"
                        class="mt-lg-2"
                    >
                      {{ $t('components.table.filters.pos.coords') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-lg col-sm-12">
                    <div class="c-table-sm-spacer"/> <!-- Spacer -->
                    <div class="input-group">
                      <b-form-input
                          v-model="positionQuery"
                          ref="positionQueryInput"
                      />
                      <div class="invalid-feedback">
                        <span>
                          {{ $t('components.table.filters.pos.error') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-lg-3 col-sm-12 text-lg-center">
                    <div class="mt-lg-2">
                      {{ $t('components.table.filters.pos.radius') }}
                    </div>
                  </div>
                  <div class="col-lg col-sm-12">
                    <div class="c-table-sm-spacer"/> <!-- Spacer -->
                    <vue-slide-bar v-model="query.position.radius" :min="0" :max="1000" class="mt-n2" />
                  </div>
                </div>
              </div>
            </div>
            </template>
          </div>
          <!-- Button -->
          <div class="c-table-spacer"/> <!-- Spacer -->
          <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-12">
              <button class="btn btn-primary btn-block" v-on:click="adhocSearch()">
                <i class="fad fa-search"></i>
                {{ $t('components.table.filters.search') }}
              </button>
            </div>
          </div>
          <div class="float-right" v-if="query.delta > 0">
            <small>Query duration {{ query.delta }}ms</small>
          </div>
        </div>
        <div class="mt-2">
          <div class="c-table-progress-overlay" ref="queryLoadingIndicator">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
              </div>
            </div>
          </div>
          <b-table class="table-responsive-sm" show-empty :items="items" :fields="fields" :current-page="currentPage" :per-page="0" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
            <template #empty="scope">
              <h4>{{ $t('components.table.filters.empty') }}</h4>
            </template>

            <template #cell(creator.cftools_id)="data">
              <template v-if="data.item.creator.cftools_id">
                <router-link :to="{name: 'profile', params: {cftools_id: data.item.creator.cftools_id}}">
                  <img
                      :src="data.item.creator.avatar"
                      alt="profile picture"
                      class="rounded-circle avatar-xs"
                  >
                  {{ data.item.creator.display_name }}
                </router-link>
              </template>
              <template v-else>
                <i>{{ $t('server.view.protocol.no_user') }}</i>
              </template>
            </template>

            <template #cell(term)="data">
              <span class="text-code font-size-13 text-white">
                {{ data.item.term }}
              </span>
            </template>

            <template #cell(action.name)="data">
              <template v-if="data.item.action.name === 'warn'">
                <b>
                  {{ $t('server.view.chatfilter.list.warn') }}
                </b> -
                {{ $t('server.view.chatfilter.list.message') }}: "{{ data.item.action.message}}"
              </template>
              <template v-else-if="data.item.action.name === 'kick'">
                <b>
                  {{ $t('server.view.chatfilter.list.kick') }}
                </b> -
                {{ $t('server.view.chatfilter.list.reason') }}: "{{ data.item.action.message}}"
              </template>
              <template v-else-if="data.item.action.name === 'ban_temporary'">
                <b>
                  {{ $t('server.view.chatfilter.list.ban_temporary') }} ({{ humanizeDuration(data.item.action.duration) }})
                </b> -
                {{ $t('server.view.chatfilter.list.reason') }}: "{{ data.item.action.message}}"
              </template>
              <template v-else-if="data.item.action.name === 'ban_permanent'">
                <b>
                  {{ $t('server.view.chatfilter.list.ban_permanent') }}
                </b> -
                {{ $t('server.view.chatfilter.list.reason') }}: "{{ data.item.action.message}}"
              </template>
            </template>

            <template #cell(actions)="data">
              <button class="btn btn-sm btn-danger m-1" v-on:click="deleteEntry(data.item.uuid)">
                <i class="far fa-trash-alt" />
              </button>
            </template>

            <template #cell(created_at)="data">
              {{ $d(parseDate(data.item.created_at), 'datetime', getDTLocale()) }}
            </template>

          </b-table>
        </div>
        <b-pagination class="float-right" size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
        <div class="float-left">
          <button class="btn btn-primary" v-on:click="addEntry()" v-if="this.options.permissions.configuration">
            <i class="fas fa-plus" />
            {{ $t('server.view.chatfilter.add.button') }}
          </button>
        </div>
        <b-modal ref="entryWizard" size="lg" centered :title="$t('server.view.chatfilter.add.title')" hide-footer>
          <form-wizard
            shape="circle"
            errorColor="#f46a6a"
            color="#4285F4"
            @on-complete="createEntry()"
            :start-index="0"
            :next-button-text="$t('server.view.chatfilter.add.next')"
            :back-button-text="$t('server.view.chatfilter.add.back')"
            :finish-button-text="$t('server.view.chatfilter.add.confirm')"
          >
            <tab-content icon="fad fa-language" :title="$t('server.view.chatfilter.add.step.term.title')" class="kyc-wizard" :before-change="validateFormTerm">
              <div class="row justify-content-center">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label for="labelTerm">
                      {{ $t('server.view.chatfilter.add.step.term.label') }}
                    </label>
                    <input
                        id="labelTerm"
                        v-model="filter.term"
                        type="text"
                        class="form-control"
                        :placeholder="$t('server.view.chatfilter.add.step.term.placeholder')"
                        :class="{ 'is-invalid': $v.filter.term.$error }"
                        autocorrect="off"
                    />
                    <div v-if="$v.filter.term.$error" class="invalid-feedback">
                      <span v-if="!$v.filter.term.required">{{ $t('server.view.chatfilter.add.step.term.required') }}</span>
                      <span v-if="!$v.filter.term.minLength">{{ $t('server.view.chatfilter.add.step.term.length') }}</span>
                      <span v-if="!$v.filter.term.maxLength">{{ $t('server.view.chatfilter.add.step.term.length') }}</span>
                    </div>
                  </div>
                  <div class="alert alert-info text-center">
                    <i class="fad fa-info-circle"/> {{ $t('server.view.chatfilter.regex') }}
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content icon="fad fa-bolt" :title="$t('server.view.chatfilter.add.step.action.title')" class="kyc-wizard" :before-change="validateForm">
              <div class="row justify-content-center">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label for="labelAction">
                      {{ $t('server.view.chatfilter.add.step.action.label') }}
                    </label>
                    <select class="form-control" id="labelAction" v-model="filter.action">
                      <option disabled selected>{{ $t('server.view.chatfilter.add.step.action.placeholder') }}</option>
                      <option value="warn">{{ $t('server.view.chatfilter.add.step.action.warn') }}</option>
                      <option value="kick">{{ $t('server.view.chatfilter.add.step.action.kick') }}</option>
                      <option value="ban">{{ $t('server.view.chatfilter.add.step.action.ban') }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="labelMessage">
                      {{ $t('server.view.chatfilter.add.step.message.label') }}
                    </label>
                    <input
                        id="labelMessage"
                        v-model="filter.message"
                        type="text"
                        class="form-control"
                        :placeholder="$t('server.view.chatfilter.add.step.message.placeholder')"
                        :class="{ 'is-invalid': $v.filter.message.$error }"
                        autocorrect="off"
                    />
                    <div v-if="$v.filter.message.$error" class="invalid-feedback">
                      <span v-if="!$v.filter.message.required">{{ $t('server.view.chatfilter.add.step.message.required') }}</span>
                      <span v-if="!$v.filter.message.minLength">{{ $t('server.view.chatfilter.add.step.message.length') }}</span>
                      <span v-if="!$v.filter.message.maxLength">{{ $t('server.view.chatfilter.add.step.message.length') }}</span>
                    </div>
                  </div>
                  <div class="form-group" v-if="filter.action === 'ban'">
                    <div class="row">
                      <div class="col-lg-4 col-sm-12">
                        <div class="justify-content-center text-center">
                          <b-form-checkbox
                              switch
                              v-model="filter.permanent"
                              class="mt-lg-2"
                          >
                            <template v-if="filter.permanent">
                              {{ $t('server.view.chatfilter.add.step.duration.permanent') }}
                            </template>
                            <template v-else>
                              {{ $t('server.view.chatfilter.add.step.duration.temporary') }}
                            </template>
                          </b-form-checkbox>
                        </div>
                      </div>
                      <div class="col-lg-8 col-sm-12">
                        <b-form-input
                            v-if="!filter.permanent"
                            v-model="filter.duration"
                            type="number"
                            :disabled="filter.permanent"
                            :class="{ 'is-invalid': filter.duration > 1051200 }"
                        />
                        <template v-if="!filter.permanent">
                          <div class="small text-danger" v-if="filter.duration > 1051200">
                            {{ $t('server.view.chatfilter.add.step.duration.error') }}
                          </div>
                          <div class="small text-muted" v-else>
                            {{ $t('server.view.chatfilter.add.step.duration.help') }}:
                            <b v-if="filter.duration !== null">
                              {{ humanizeDuration(filter.duration)  }}
                            </b>
                            <b v-else>
                              -
                            </b>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>
          </form-wizard>
        </b-modal>
      </div>
    </div>
  </div>
  <div v-else>
    <template v-if="banlistRequirement">
      <div class="row">
        <div class="col justify-content-center">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <i class="fad fa-empty-set info-component-icon"></i>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-lg-12">
                <h3 v-b-tooltip.hover title="Wow such empty 👻">{{$t('server.view.chatfilter.banlist.title')}}</h3>
                <h5 class="text-muted">{{$t('server.view.chatfilter.banlist.description')}}</h5>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <a href="#" v-on:click="openSettings($event)">
                  <button class="btn btn-primary btn-lg text-uppercase">
                    <h5 class="mb-0">
                      {{$t('server.view.chatfilter.banlist.button')}}
                    </h5>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col justify-content-center">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <i class="fad fa-empty-set info-component-icon"></i>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-lg-12">
                <h3 v-b-tooltip.hover title="Wow such empty 👻">{{$t('server.view.chatfilter.empty.title')}}</h3>
                <h5 class="text-muted">{{$t('server.view.chatfilter.empty.description')}}</h5>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <a href="#" v-on:click="openSettings($event)">
                  <button class="btn btn-primary btn-lg text-uppercase">
                    <h5 class="mb-0">
                      {{$t('server.view.chatfilter.empty.button')}}
                    </h5>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
